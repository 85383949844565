import "./reset.css";
import React, { memo } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import "./layout.css";

// 子コンポーネントが変更された場合のみ再レンダリング
const Layout = memo(({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1136,
        }}
      >
        <main>{children}</main>        
      </div>
      <Footer />
    </>
  );
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;