// src/components/footer.js を修正
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { memo } from "react"; // memo をインポート
import "./footer.css";

// React.memo でラップ
const Footer = memo(({ siteTitle }) => {
  return(
    <footer>
      <div className="contact"> 
        <nav>
          <a href="https://twitter.com/wentz_design" target="_blank" rel="noopener noreferrer" aria-label="twitter">
            X a.k.a Twitter
          </a>
          <a href="https://note.com/wentz_design" target="_blank" rel="noopener noreferrer" aria-label="note">
            note
          </a>
          <a href="mailto:mail@wentz-design.com" aria-label="mail">
            E-mail
          </a>
          <a href="/rss.xml" target="_blank" rel="noopener noreferrer" aria-label="rss">
            RSS
          </a>
        </nav>
      </div>
      <div className="copyright"><span>© Masanori Ueda. All RIght reseved.</span>　・　<Link to="/privacy-policy">Privacy Policy</Link></div>
    </footer>
  );
});

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;